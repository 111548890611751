import { Client } from 'boardgame.io/react';
import { BaseArcs } from '../game/Game';
import { ArcsBaseBoard } from './components/ArcsBaseBoard';

const App = Client({
    game: BaseArcs,
    board: ArcsBaseBoard,
    debug: true
 });

export default App;