import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { VerticalInterface } from './VerticalInterface';
import Drawer from './Drawer';
import { AmbitionChapterTracker } from './AmbitionChapterTracker';
import { LeftDrawer } from './LeftDrawer';

export function ArcsBaseBoard(props) {
    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [showRightDrawer, setShowRightDrawer] = useState(false);
    const [showLeftDrawer, setShowLeftDrawer] = useState(false);

    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                // @ts-ignore
                width: targetRef.current.offsetWidth,
                // @ts-ignore
                height: targetRef.current.offsetHeight
            });
        }
    }, []);

    let winner = <></>;
    if (props.ctx.gameover) {
        winner =
            props.ctx.gameover.winner !== undefined ? (
            <div id="winner">Winner: {props.ctx.gameover.winner}</div>
            ) : (
                <div id="winner">Draw!</div>
            );
    }

    return (
        <Background ref={targetRef}>
            {
                dimensions.height > dimensions.width ?
                    <>
                        <VerticalInterface
                            {...props}
                        />
                        <ExpandRight onClick={() => setShowRightDrawer(!showRightDrawer)}>{"<<"}</ExpandRight>
                        <ExpandLeft onClick={() => setShowLeftDrawer(!showLeftDrawer)}>{">>"}</ExpandLeft> 
                        <Drawer
                            open={showRightDrawer}
                            side="right"
                            onClose={() => setShowRightDrawer(false)}>
                            <MobileDrawer>
                                <AmbitionChapterTracker />
                            </MobileDrawer>
                        </Drawer>
                        <Drawer
                            open={showLeftDrawer}
                            side="left"
                            onClose={() => setShowLeftDrawer(false)}>
                            <MobileDrawer>
                                <LeftDrawer />
                            </MobileDrawer>
                        </Drawer>
                    </>
                    :
                    <DesctopRow>
                        <DesktopDrawer>
                            <LeftDrawer />
                        </DesktopDrawer>
                        <DesctopVerticalInterface>
                            <VerticalInterface
                                {...props}
                            />
                        </DesctopVerticalInterface>
                        <DesktopDrawer>
                            <AmbitionChapterTracker />
                        </DesktopDrawer>
                    </DesctopRow>
            }
            {winner}
        </Background>
    );
}

const DesktopDrawer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 30%;
`;
const MobileDrawer = styled.div`
    display: flex;
    justify-content: center;
    background: black;
    align-items: center;
    height: 100%;
`;
const DesctopRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
`;

const DesctopVerticalInterface = styled.div`
    border-left: 1px solid white;
    border-right: 1px solid white;
    width: 40%;
    padding-left: 16px;
    padding-right: 16px;
    min-width: 400px;
`
const Background = styled.div`
    background: black;
    color: white;
    height: 100vh;
    width: 100vw;
`;

const ExpandRight = styled.div`
    position: absolute;
    top: 64px;
    right: 16px;
    cursor: pointer;
`;
const ExpandLeft = styled.div`
    position: absolute;
    top: 64px;
    left: 16px;
    cursor: pointer;
`;