import styled from "styled-components"
import React from "react";


export function RoundCards(props) {
    // todo use a shared cards display component to display played cards for the round 
    // along with probably a button for viewing discards
    return (
        <TopRowSection>
            TODO: Played Cards this round
        </TopRowSection>
    );
}

const TopRowSection = styled.div`
    min-height: 100px;
    margin: 8px;
    padding: 8px;
    width: 50%;
    border: 1px solid white;
`;
