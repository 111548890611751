import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getColorFromOwner } from "../../util/getColorFromOwner";
import shipMask from '../images/base/token/single-ship-black.png';


export function SystemShips({ ships, top, left }) {
    const [headerHeight, setHeaderHeight] = useState(0)
    const [playerDetails, setPlayerDetails] = useState([])

    const headerRef = useRef(null);
    useEffect(() => {
        setHeaderHeight(headerRef.current.clientHeight)
    })

    useEffect(() => {
        // group ships by owner
        const newPlayerDetails = []
        const shipsByOwner = ships.reduce((acc, ship) => {
            if (!acc[ship.owner]) {
                acc[ship.owner] = []
            }
            acc[ship.owner].push(ship)
            return acc
        }, {}); 
        for (let owner in shipsByOwner) {
            const damaged = shipsByOwner[owner].filter(ship => ship.health === "damaged");
            // get the count of each damaged ship
            const damagedCount = damaged.reduce((s, ship) => s + ship.quantity, 0);
            const fresh = shipsByOwner[owner].filter(ship => ship.health === "fresh");
            // get the count of each fresh ship
            const freshCount = fresh.reduce((s, ship) => s + ship.quantity, 0);
            newPlayerDetails.push({ owner, damagedCount, freshCount })
        }       
        setPlayerDetails(newPlayerDetails)
    }, [ships])
    const playerDetailsWidth = `${100 / playerDetails.length}%`;
    let numberFontSize = `${headerHeight * .4}px`;
    return (
        <ShipsContainer ref={headerRef} style={{ top: `${top}%`, left: `${left}%`, zIndex: 7 }} >
            {playerDetails.map((playerDetails, idx) => {
                const color = getColorFromOwner(playerDetails.owner);
                let shipTop = 0;
                if (idx > 1) {
                    shipTop = 50;
                }
                let shipLeft = 0;
                if (idx % 2 === 1) {
                    shipLeft = 50;
                }
                return (
                    <>
                        <ColoredShip
                            color={color}
                            style={{
                                top: `${shipTop}%`,
                                left: `${shipLeft}%`,
                            }}
                        />    
                        <CountContainer
                            style={{
                                top: `${shipTop+30}%`,
                                left: `${shipLeft+25}%`,
                            }}>
                            <div>
                                {`${playerDetails.freshCount} / ${playerDetails.damagedCount + playerDetails.freshCount}`}
                            </div>
                            
                        </CountContainer>
                    </>
                )
            })}
        </ShipsContainer>
    )
}

const ShipsContainer = styled.div`
    position: absolute;
    height: 8%;
    width: 10%;
    display: flex;
    flex-direction: row;
    border-radius: 20%;
`;

const ColoredShip = styled.div`
    height: 50%;
    position: absolute;
    width: 50%;
    background: ${({ color }) => color || 'red'};
    -webkit-mask-image: url(${shipMask});
    mask-image: url(${shipMask});
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
    z-index: 5;
`;

const CountContainer = styled.div`
    position: absolute;
    background: white;
    border: .5px solid black;
    border-radius: 20%;
    width: 34%;
    height: 21%;
    z-index: 10;
    color: black;
    text-align: center;
    vertical-align: middle;
    display: block;
    container-type: size;
    div {
        margin-top: -8%;
        font-size: 100cqh;
        font-weight: bold;
    }
`;