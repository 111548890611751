//TODO - handle buildings, handle updating the user's supply, or moving the piece to trophies/captives

export default function removePiece(G, pieceType, system, playerID, health) {
  const pieceIndex = G.map[system].pieces.findIndex((piece) => piece.owner === playerID && piece.health === "damaged" && piece.type === pieceType && piece.health === health);

  if (pieceType === "ship") {
    //Decrement the ship quantity
    G.map[system].pieces[pieceIndex].quantity--;

    //Remove the ship stack if that was the last one
    if (G.map[system].pieces[pieceIndex].quantity === 0) G.map[system].pieces.splice(pieceIndex, 1);
  }
}
