//TODO - validate that the player has the right kind of piece in their supply

import { INVALID_MOVE } from "boardgame.io/core";
import { exploitBuilding, addPiece, getSystemController } from "../../util";

export default function build({ G, playerID }, pieceType, system) {
  //check for legality
  const invalidMove = buildIsInvalid({ G, playerID }, pieceType, system);
  if (invalidMove) {
    console.warn(invalidMove);
    throw INVALID_MOVE;
  }

  //determine if the piece should be fresh or damaged
  const controller = getSystemController(G.map[system]);
  const health = controller === playerID || controller === null ? "fresh" : "damaged";

  addPiece(G, pieceType, system, playerID, health);
  if (pieceType === "ship") exploitBuilding(G, "startport", system, playerID);
}

const buildIsInvalid = function ({ G, playerID }, pieceType, system) {
  const pieces = G.map[system]?.pieces || [];
  if (!G.map[system]) {
    return "Invalid system";
  }
  if (!["ship", "city", "starport"].includes(pieceType)) {
    return "Invalid piece type";
  }
  if (!pieces.some((piece) => piece.owner === playerID)) {
    return "Requires loyal piece";
  }
  if (pieceType === "ship" && !pieces.some((piece) => piece.owner === playerID && piece.type === "starport" && !piece.used)) {
    return "Requires unused starport";
  }
  if (["city", "starport"].includes(pieceType) && pieces.filter((piece) => ["city", "starport"].includes(piece.type) && piece.slot > 0).length >= G.map[system].slots) {
    return "No free slots";
  }
};
