/*Ship building coordinates
10	45%	25.6%
11	45%	25.6%
12	50.8%	0.9%
13	75.5%	0.4%
20	60.6%	38.3%
21	83.7%	11.8%
22	75.9%	34.1%
23	89.2%	43.6%
30	59.1%	55.2%
31	73.9%	55.8%
32	78%	72.3%
33	75.7%	90.9%
40	41.7%	65.8%
41	51.6%	91.6%
42	41%	76.1%
43	18.8%	89.5%
50	30.6%	50.1%
51	16.2%	69.4%
52	1.4%	60.9%
53	11.8%	45.6%
60	31.5%	34.4%
61	1.8%	27.9%
62	16.2%	23%
63	13.7%	0.2%
*/
export const CoordinateMap = {
    10: {
        shipCoordinates: {
            left: 45,
            top: 25.6
        },        
    },
    11: {
        shipCoordinates: {
            left: 33,
            top: 0.5
        },
        buildingSlots: [
            {
                left: 39.2,
                top: 8.5
            },
            {
                left: 43.8,
                top: 5.8
            }
        ]
    },
    12: {
        shipCoordinates: {
            left: 50.8,
            top: 0.9
        },
        buildingSlots: [
            {
                left: 54.7,
                top: 8
            }
        ]
    },
    13: {
        shipCoordinates: {
            left: 75.5,
            top: 0.4
        },
        buildingSlots: [
            {
                left: 67,
                top: 4
            },
            {
                left: 71,
                top: 8.5
            }
        ]
    },
    20: {
        shipCoordinates: {
            left: 60.6,
            top: 38.3
        },
    },
    21: {
        shipCoordinates: {
            left: 87,
            top: 11
        },
        buildingSlots: [
            {
                left: 77.8,
                top: 20.6
            }
        ]
    },
    22: {
        buildingSlots: [
            {
                left: 89.4,
                top: 31
            }
        ],
        shipCoordinates: {
            left: 75.9,
            top: 34.1
        }
    },
    23: {
        buildingSlots: [
            {
                left: 82,
                top: 45.5
            },
            {
                left: 86.2,
                top: 48.5
            }
        ],
        shipCoordinates: {
            left: 89.2,
            top: 43.6
        }
    },
    30: {
        buildingSlots: [
            {
                left: 59.1,
                top: 55.2
            }
        ],
        shipCoordinates: {
            left: 59.1,
            top: 55.2
        }
    },
    31: {
        buildingSlots: [
            {
                left: 84.9,
                top: 59.5
            }
        ],
        shipCoordinates: {
            left: 73.9,
            top: 55.8
        }
    },
    32: {
        buildingSlots: [
            {
                left: 71.1,
                top: 67.4
            }
        ],
        shipCoordinates: {
            left: 78,
            top: 72.3
        }
    },
    33: {
        buildingSlots: [
            {
                left: 74.5,
                top: 83.7
            },
            {
                left: 70.6,
                top: 88.1
            }
        ],
        shipCoordinates: {
            left: 75.7,
            top: 90.9
        }
    },
    40: {
        buildingSlots: [
            {
                left: 41.7,
                top: 65.8
            }
        ],
        shipCoordinates: {
            left: 41.7,
            top: 65.8
        }
    },
    41: {
        buildingSlots: [
            {
                left: 58.5,
                top: 85.9
            },
            {
                left: 54.5,
                top: 81.6
            }
        ],
        shipCoordinates: {
            left: 51.6,
            top: 91.6
        }
    },
    42: {
        buildingSlots: [
            {
                left: 43.6,
                top: 90.9
            },
            {
                left: 39.6,
                top: 86.5
            }
        ],
        shipCoordinates: {
            left: 41,
            top: 76.1
        }
    },
    43: {
        buildingSlots: [
            {
                left: 28.3,
                top: 82.1
            }
        ],
        shipCoordinates: {
            left: 18.8,
            top: 89.5
        }
    },
    50: {
        buildingSlots: [
            {
                left: 30.6,
                top: 50.1
            }
        ],
        shipCoordinates: {
            left: 30.6,
            top: 50.1
        }
    },
    51: {
        buildingSlots: [
            {
                left: 7.3,
                top: 79.4
            }
        ],
        shipCoordinates: {
            left: 16.2,
            top: 69.4
        }
    },
    52: {
        buildingSlots: [
            {
                left: 14.4,
                top: 58
            }
        ],
        shipCoordinates: {
            left: 1.4,
            top: 60.9
        }
    },
    53: {
        buildingSlots: [
            {
                left: 2.1,
                top: 50.4
            },
            {
                left: 6.1,
                top: 46.2
            }
        ],
        shipCoordinates: {
            left: 11.8,
            top: 45.6
        }
    },
    60: {
        buildingSlots: [
            {
                left: 31.5,
                top: 34.4
            }
        ],
        shipCoordinates: {
            left: 31.5,
            top: 34.4
        }
    },
    61: {
        buildingSlots: [
            {
                left: 14.4,
                top: 35.1
            }
        ],
        shipCoordinates: {
            left: 1.8,
            top: 27.9
        }
    },
    62: {
        buildingSlots: [
            {
                left: 9.1,
                top: 17.5
            },
            {
                left: 13,
                top: 14
            }
        ],
        shipCoordinates: {
            left: 16.2,
            top: 23
        }
    },
    63: {
        buildingSlots: [
            {
                left: 24.3,
                top: 9.1
            }
        ],
        shipCoordinates: {
            left: 13.7,
            top: 0.2
        }
    }
}
