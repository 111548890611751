//TODO - update the pieces in a player's supply

export default function addPiece(G, pieceType, system, playerID, health) {
  if (["city", "starport"].includes(pieceType)) {
    //figure out next free slot
    let slotNum;
    for (let i = 1; i < G.map[system].slots + 1; i++) {
      if (!G.map[system].pieces.some((piece) => piece.slot === i)) {
        slotNum = i;
        break;
      }
    }

    //add the piece to the slot
    G.map[system].pieces.push({
      type: pieceType,
      health: health,
      owner: playerID,
      slot: slotNum,
      used: false,
    });
  } else {
    //Find existing ship stack, if exists. Find next unused starport.
    const shipIndex = G.map[system].pieces.findIndex((piece) => piece.type === "ship" && piece.owner === playerID && piece.health === health);

    //Increment the ship stack, or add a new one
    if (shipIndex >= 0) {
      G.map[system].pieces[shipIndex].quantity++;
    } else {
      G.map[system].pieces.push({
        type: "ship",
        health: health,
        owner: playerID,
        quantity: 1,
      });
    }
  }
}
