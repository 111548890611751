import { useEffect, useState } from "react";
import styled from "styled-components";
import React from "react";
import { Map } from "./Map";
import { RoundCards } from "./RoundCards";
import { PlayerBoard } from "./PlayerBoard";
import HandOfCards from "./HandOfCards";

export function VerticalInterface({ ctx, G, moves }) {
    const [actionhand, setActionHand] = useState([]);
    useEffect(() => {
        const suits = ['mob', 'agg', 'admin', 'con'];
        const numbers = [1, 2, 3, 4, 5, 6, 7];
        const newHand = [];
        for (let i = 0; i < 6; i++) {
            let foundCard = false;
            while (!foundCard) {
                const handString = suits[Math.floor(Math.random() * suits.length)] + numbers[Math.floor(Math.random() * numbers.length)];
                if(newHand.indexOf(handString) === -1) {
                    newHand.push(`base/card/action/${handString}.jpg`);
                foundCard = true;
                }
            }
            setActionHand(newHand);
        }
    }, []);

    return (
        <ContainerDiv>
            <Map
                ctx={ctx}
                G={G}
                moves={moves}
            />
            <TopRow>
                <RoundCards />
                <PlayerBoard
                    color="red"
                    cities={undefined}
                    resources={undefined} />
            </TopRow>
            <ButtonsContainer>
                TODO: Buttons to make moves
            </ButtonsContainer>
            <BottomContainer>
                <BottomCardsContainer>
                    <HandOfCards
                        hand={actionhand}
                        title={"Hand"}
                    />
                </BottomCardsContainer>
                <BottomCardsContainer>
                    <HandOfCards
                        hand={[`base/card/court/1.jpg`, `base/card/court/4.jpg`]}
                        title={"Court"}
                        side={"right"}
                    />
                </BottomCardsContainer>
            </BottomContainer>
        </ContainerDiv>
    );
}


const TopRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 16px;
`;

const ButtonsContainer = styled.div`
    margin: 8px;
    padding: 8px;
    border: 1px solid white;
    width: auto;
    height: 100%;
`
const ContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: auto;
    width: 100%;
`;

const BottomCardsContainer = styled.div`
    width: 50%;
`;
