import styled from "styled-components";
import React from "react";
import triangleMask from '../images/base/token/triangle-mask2.png';
import damagedBuilding from '../images/base/token/building/damaged-building.png';

// Component to render the map triangle token
export function MapTriangleToken({ image, color, top, left, damaged }) {
    return (
        <ImageContainer style={{ top: `${top}%`, left: `${left}%`, zIndex: 4 }}>
            <ColoredTriangle color={color} />
            <TokenImage src={image} alt="img-details" />
            {damaged && <DamagedImage src={damagedBuilding} alt="damaged" />}
        </ImageContainer>
    );
}

// Styled container to position the component
const ImageContainer = styled.div`
    position: absolute;
    height: 6.3%;
    width: 4.6%;
`;

// Styled component for the colored triangle
const ColoredTriangle = styled.div`
    height: 100%;
    width: 100%;
    background: ${({ color }) => color || 'red'};
    -webkit-mask-image: url(${triangleMask});
    mask-image: url(${triangleMask});
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
    z-index: 5;
`;

// Styled component for the token image
const TokenImage = styled.img`
    height: 50%;
    width: 50%;
    position: absolute;
    top: 30%;
    left: 26%;
    z-index: 6;
`;

const DamagedImage = styled.img`
    height: 108%;
    width: 108%;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: -3%;
    margin-top: -3%;
    z-index: 7;
`;
