//TODO - In rare cases, the player might care if the repair hits a used building or unused building. Overseer for instance. I think if we always repair an unused piece if possible, we should be good.

import { INVALID_MOVE } from "boardgame.io/core";
import { removePiece, addPiece } from "../../util";

export default function repair({ G, playerID }, pieceType, system) {
  //check for legality
  const invalidMove = repairIsInvalid({ G, playerID }, pieceType, system);
  if (invalidMove) {
    console.warn(invalidMove);
    throw INVALID_MOVE;
  }

  //Find damaged piece
  const existingPiece = G.map[system].pieces.findIndex((piece) => piece.owner === playerID && piece.health === "damaged" && piece.type === pieceType);

  if (["city", "starport"].includes(pieceType)) {
    G.map[system].pieces[existingPiece].health = "healthy";
  } else {
    removePiece(G, "ship", system, playerID, "damaged");
    addPiece(G, "ship", system, playerID, "fresh");
  }
}

const repairIsInvalid = function ({ G, playerID }, pieceType, system) {
  const pieces = G.map[system]?.pieces || [];
  if (!G.map[system]) {
    return "Invalid system";
  }
  if (!["ship", "city", "starport"].includes(pieceType)) {
    return "Invalid piece type";
  }
  if (!pieces.some((piece) => piece.owner === playerID && piece.health === "damaged" && piece.type === pieceType)) {
    return "Requires loyal damaged piece of specified type";
  }
};
