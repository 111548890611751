import styled from "styled-components"
import React from "react";
import playerboard from '../images/base/board/playerboard/playerboard.jpg';


export function PlayerBoard({ color, cities, resources, }) {
    const CityTriangle = ({idx}) => {
        return (<City 
// @ts-ignore
        idx={idx}>
            <CityDiamond/>
        </City>)
    }
    return (
        <TopRowSection>
            <CityCircleRow>
                <ResourceCircle 
                // @ts-ignore
                    idx={0} />
                <ResourceCircle
                    // @ts-ignore
                    idx={1} />
                <ResourceCircle
                    // @ts-ignore
                    idx={2} />
                <ResourceCircle
                    // @ts-ignore
                    idx={3} />
                <ResourceCircle
                    // @ts-ignore
                    idx={4} />
                <ResourceCircle
                    // @ts-ignore
                    idx={5} />
                <CityTriangle
                    // @ts-ignore
                    idx={2} />
                <CityTriangle
                    // @ts-ignore
                    idx={3} />
                <CityTriangle
                    // @ts-ignore
                    idx={4.5} />
                <CityTriangle
                    // @ts-ignore
                    idx={6} />
                <CityTriangle
                    // @ts-ignore
                    idx={7} />
            </CityCircleRow>
            <ResourcesCaptivesRow>
                <OutrageTracker>
                    <TopOutrageRow>
                        <OutrageResource />
                        <OutrageResource />
                    </TopOutrageRow>
                    <BottomOutrageRow>
                        <OutrageResource />
                        <OutrageResource />
                        <OutrageResource />
                    </BottomOutrageRow>
                </OutrageTracker>
                <Trophies>
                    Trophies
                    
                </Trophies>
                <Captives>
                    Captives
                </Captives>
            </ResourcesCaptivesRow>
        </TopRowSection>
    );
}

const OutrageTracker = styled.div`
    width: 40%;
    border: 1px solid white;
`;

const OutrageResource = styled.div`
    width: 30%;
    border-radius: 50%;
    aspect-ratio: 1/1;
    border: 1px solid white;
`;
const TopOutrageRow = styled.div`
    display: flex;
    flex-direction: row;
    height: 50%;
    justify-content: center;
`;
const BottomOutrageRow = styled.div`
    display: flex;
    flex-direction: row;
    height: 50%;
`;
const Captives = styled.div`
    width: 40%;
    border: 1px solid white;
`;
const Trophies = styled.div`
    width: 40%;
    border: 1px solid white;
`;
const ResourcesCaptivesRow = styled.div`
    height: 60%;
    display: flex;
    flex-direction: row;
`;
const CityCircleRow = styled.div`
    height: 40%;
    position: relative;
`;

const City = styled.div`
    position: absolute;
    left: ${(props) =>
        // @ts-ignore
        props.idx * 12 + 1}%;
    width: 11%;
    aspect-ratio: 1;
`;

const CityDiamond = styled.div`
    left: 50%;
    top: 50%;
    rotate: 45deg;
    width: 100%;
    height: 100%;
    border: 1px solid white;
`;

const ResourceCircle = styled.div`
    position: absolute;
    left: ${(props) =>
        // @ts-ignore
        props.idx * 12 + 1}%;
    width: 11%;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 1px solid white;
`;
const TopRowSection = styled.div`
    min-height: 100px;
    margin: 8px;
    padding: 8px;
    width: 50%;
    border: 1px solid white
`;
