import React, { useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components"


const HandOfCards = ({ hand, title, side="left" }) => {
    const rowRef = useRef();
    const [rowHeight, setRowHeight] = useState(0);
    const [hoveredCard, setHoveredCard] = useState(-1);
    useLayoutEffect(() => {
        if (rowRef.current) {
            // @ts-ignore
            setRowHeight(rowRef.current.offsetHeight);
        }
    }, []);

    const getImage = (handString, idx, handLength) => {
        return <ActionCardImage
            // @ts-ignore
            side={side}
            key={idx}
            hovered={hoveredCard === idx}
            handLength={handLength}
            rowHeight={rowHeight}
            idx = {idx}
            className={`hand-${hand.length} ${idx === 0 ? 'first' : ''} ${idx === hand.length - 1 ? 'last' : ''} `}
            src={require(`../images/${handString}`)}
            alt={handString}
            onMouseEnter={() => setHoveredCard(idx)}
            onMouseLeave={() => setHoveredCard(null)} />;
    }
    return (
        <>            
            <TextDiv>{title}</TextDiv>
            <CardsRow ref={rowRef}>
                <SubRow>
                    {hand.length === 0 && <div>No cards in hand</div>}
                    {hand.map((card, idx) => getImage(card, idx, hand.length))}
                </SubRow>
            </CardsRow>
        </>
    );
}


// I need images in here to fill the horizontal space and not overflow
const CardsRow = styled.div`
    min-height: 20vh;
    margin: 8px;
    width: 100%;
`;
const SubRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    min-height: 20vh;
    width: 100%;
    overflow: hidden;
`;

const TextDiv = styled.div`
`

const ActionCardImage = styled.img`    
    position: absolute;
    border-radius: 4px;
    border: 2px solid black;
    height: ${(props) => props.
// @ts-ignore
    rowHeight}px;
    z-index: ${(props) => 
// @ts-ignore
    props.hovered ? props.handLength + 1 : props.idx + 1};
    ${(props) => props.
// @ts-ignore
    side === "left" ? `left: ${props.idx * (props.rowHeight * .17)}px;` : `right: ${props.idx * (props.rowHeight * .15)}px;`}
`;
export default HandOfCards;