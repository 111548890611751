import styled from "styled-components"
import React from "react";


export function LeftDrawer(props) {
    return (
        <div>
            TODO: Other player boards, your leader, other stuff
        </div>
    );
}


