import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import base_bard from '../images/base/board/map-only.jpg';
import { Starport } from "./Starport";
import { City } from "./City";
import build from "../../game/basic-actions/build";
import { getColorFromOwner } from "../../util/getColorFromOwner";
import { SystemShips } from "./SystemShips";
import { CoordinateMap } from "../../util/coordinateMap";

export function Map({ ctx, G, moves }) {
    const [components, setComponents] = useState([]);


    useEffect(() => {
        const newComponents = [];
        for (let key in G.map) {
            console.log("Displaying tokens for: ",key);
            if (key in CoordinateMap === false) continue;
            console.log(1);
            const { buildingSlots, shipCoordinates } = CoordinateMap[key];
            const { pieces } = G.map[key];
            if (!pieces?.length) continue;
            console.log(2);
            const ships = [];
            for(const piece of pieces) {
                if (piece.type === "city") {
                    const damaged = piece.health === "damaged";
                    const color = getColorFromOwner(piece.owner);
                    let coordinates = buildingSlots[0];
                    if(piece.slot === 2) {
                        coordinates = buildingSlots[1];
                    }
                    newComponents.push(<City color={color} top={coordinates.top} left={coordinates.left} damaged={damaged} />)
                } else if (piece.type === "starport") {
                    const damaged = piece.health === "damaged";
                    const color = getColorFromOwner(piece.owner);
                    let coordinates = buildingSlots[0];
                    if (piece.slot === 2) {
                        coordinates = buildingSlots[1];
                    }
                    newComponents.push(<Starport color={color} top={coordinates.top} left={coordinates.left} damaged={damaged} />)
                } else if (piece.type === "ship") {
                    console.log(3);
                    ships.push(piece);                
                }
            }
            console.log(shipCoordinates?.top);
            console.log(ships);
            if (ships.length && shipCoordinates?.top) {
                newComponents.push(<SystemShips ships={ships} top={shipCoordinates.top} left={shipCoordinates.left} />)
            }
        }
        setComponents(newComponents);
    }, [G])

    // get every prop in coordinate map and create an array of components
    
    return (
        <MapContainer>
            <MapImage src={base_bard} alt="board">
            </MapImage>
            {/* This is an example token on the map, we need to get coordinates in percentages for all locations and dynamically render the game state */}
            {
                components.map((component) => component)
            }
        </MapContainer>
    )
}




const MapContainer = styled.div`
    width: 100%;
    position: relative;
`
const MapImage = styled.img`
    width: 100%;
`;