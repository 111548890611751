// Drawer.jsx
import React from "react";
import styled from "styled-components";



const Drawer = ({ open, side, onClose, children }) => {
  return (
    <>
      <DrawerWrapper
        // @ts-ignore
        open={open}
        side={side}>
        <CloseButton 
          // @ts-ignore
          side={side} onClick={onClose}>
          X
        </CloseButton>
        {children}
      </DrawerWrapper>
      <Overlay
        // @ts-ignore
        open={open} onClick={onClose} />
    </>
  );
};


const DrawerWrapper = styled.div`
  position: fixed;
  top: 0;
  ${(props) => (props.
// @ts-ignore
  side === "left" ? "left: 0;" : "right: 0;")}
  height: 100%;
  width: 90vw;
  background-color: #fff;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  transform: ${(props) =>
        // @ts-ignore
        props.open
            ? "translateX(0)"
            // @ts-ignore
            : props.side === "left"
                ? "translateX(-100%)"
                : "translateX(100%)"};
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999; 
  display: ${(props) => (props.
// @ts-ignore
  open ? "block" : "none")};
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  ${(props) => (props.
// @ts-ignore
  side === "left" ? "right: 10px;" : "right: 10px;")}
  background: none;
  border: none;
  cursor: pointer;
`;

export default Drawer;