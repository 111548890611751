
export const getColorFromOwner = (owner) => {
    switch (owner) {
        case "0": return "#FB0B0B"; // red
        case "1": return "#3869FF";
        case "2": return "#F2F810";
        case "3": return "#F1F1F1";
        case "4": return "darkgreen";
        default: return "grey";
    }
}