import build from "./basic-actions/build";
import repair from "./basic-actions/repair";

export const BaseArcs = {
  setup: function () {
    return {
      map: this.getBoardLayout(),
    };
  },

  moves: {
    build: build,
    repair: repair,
  },

  getBoardLayout: () => ({
    10: {
      cluster: 1,
      type: "gate",
      adjacent: [11, 12, 13, 20, 60],
      pieces: [
        { type: "ship", owner: "1", health: "fresh", quantity: 2 },
        { type: "ship", owner: "0", health: "damaged", quantity: 1 },
        { type: "ship", owner: "3", health: "damaged", quantity: 1 },
        { type: "ship", owner: "2", health: "fresh", quantity: 4 },
        { type: "ship", owner: "2", health: "damaged", quantity: 2 }
      ],
    },
    11: {
      cluster: 1,
      type: "weapon",
      slots: 2,
      adjacent: [10, 11],
      pieces: [
        { type: "ship", owner: "0", health: "damaged", quantity: 2 },
        { type: "ship", owner: "1", health: "fresh", quantity: 2 },
        { type: "starport", owner: "0", health: "fresh", used: false, slot: 1 },
        { type: "city", owner: "0", health: "damaged", used: false, slot: 2 },
        { type: "ship", owner: "2", health: "fresh", quantity: 2 },
        { type: "ship", owner: "3", health: "fresh", quantity: 2 },
      ],
    },
    12: {
      cluster: 1,
      type: "fuel",
      slots: 1,
      adjacent: [10, 11, 13],
      pieces: [{ type: "ship", owner: "0", health: "fresh", quantity: 2 }],
    },
    13: {
      cluster: 1,
      type: "material",
      slots: 2,
      adjacent: [10, 12],
    },
    20: {
      cluster: 2,
      type: "gate",
      adjacent: [21, 22, 23, 30, 10],
    },
    21: {
      pieces: [
        { type: "ship", owner: "0", health: "damaged", quantity: 2 },
        { type: "ship", owner: "1", health: "fresh", quantity: 2 }
      ]
    },
    22: {},
    23: {},
    30: {
      cluster: 3,
      type: "gate",
      adjacent: [31, 32, 33, 40, 20],
    },
    31: {},
    32: {},
    33: {},
    40: {
      cluster: 4,
      type: "gate",
      adjacent: [41, 42, 43, 50, 30],
    },
    41: {},
    42: {},
    43: {},
    50: {
      cluster: 5,
      type: "gate",
      adjacent: [51, 51, 52, 40, 60],
      pieces: [
        { type: "ship", owner: "2", health: "damaged", quantity: 2 },
        { type: "ship", owner: "3", health: "fresh", quantity: 2 },
      ]
    },
    51: {},
    52: {},
    53: {},
    60: {
      cluster: 6,
      type: "gate",
      adjacent: [61, 62, 63, 10, 50],
    },
    61: {},
    62: {
      pieces: [
        { type: "ship", owner: "0", health: "damaged", quantity: 2 },
        { type: "ship", owner: "1", health: "damaged", quantity: 2 },
        { type: "ship", owner: "3", health: "fresh", quantity: 2 },
        { type: "starport", owner: "3", health: "damaged", used: false, slot: 1 }
      ]
},
    63: {},
  }),
};
