export default function getSystemController(system) {
  if (!system.pieces) return null;

  const freshShips = system.pieces.filter((piece) => piece.type === "ship" && piece.health === "fresh");
  const playerTotals = freshShips.reduce((totals, piece) => {
    if (!totals[piece.owner]) {
      totals[piece.owner] = 0;
    }
    totals[piece.owner] += piece.quantity;
    return totals;
  }, {});

  const controller = Object.entries(playerTotals).reduce(
    (max, [player, total]) => {
      if (total > max.total) {
        return { player: player, total };
      }
      return max;
    },
    { player: null, total: 0 }
  ).player;

  return controller;
}
